import domtoimage from "dom-to-image";

import { useLocation } from "react-router-dom";
import { createBook, setSelectCover, initBookApiV2, getPrebooks, removePrebookApi } from "../ApiCalls";
import "./style.css";
import "./fonts.css";
import Stage1 from "./components/Stage1";
import Stage2 from "./components/Stage2";
import Stage3 from "./components/Stage3/Stage3";
import Stage4 from "./components/Stage4/Stage4";
import React, { useState, useEffect, useContext } from "react";
import Header from "./components/Header";
import { motion } from "framer-motion";
import { MainContext } from "../ContextState";
import LogRocket from "logrocket";
import NotComplitedBooks from "./components/NotComplitedBooks";
import { normalizePhoneNumber } from "../common/utils";
import { chooseLang } from "../common/chooseLang";
import { nanoid } from "nanoid";
import { EDITOR_URL } from "../consts";
import { identifyUser, trackMixEvent } from "../common/eventManager";
import OtpStage from "./components/Stage_otp";
import { STAGES_STEPS } from "../common/dataMaps";


const KEYS_TO_STORE = ["email", "faceImageUrl", "name", "age", "croppedFaceImageUrl", "phone", "bookId", "eyeglasses", "boundingBox", "faceDataId"];

function Stages() {
    const location = useLocation();

    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || "he";
    const debug = queryParameters.get("debug") || false;
    const showLogo = queryParameters.get("logo") || false;
    const isFullPage = queryParameters.get("isFullPage") || false;
    const sliderV2 = queryParameters.get("sliderV2") || true;
    const templateId = queryParameters.get("templateId") || null;

    const email = localStorage.getItem("email") || "";
    let phone = localStorage.getItem("phone") || "";
    const faceDataId = localStorage.getItem("faceDataId") || "";
    const eyeglasses = localStorage.getItem("eyeglasses") || false;
    const boundingBox = localStorage.getItem("boundingBox");
    const faceImageUrl = localStorage.getItem("faceImageUrl") || "";
    const croppedFaceImageUrl = localStorage.getItem("croppedFaceImageUrl") || "";
    const heroName = localStorage.getItem("name") || "";
    const age = localStorage.getItem("age") || "";
    const imageBlobStorage = localStorage.getItem("imageBlob") || null;

    const bookDataStorage = localStorage.getItem("bookDataStorage");
    let bookDataStorageJson = null;
    if (debug) {
        if (bookDataStorage) {
            bookDataStorageJson = JSON.parse(bookDataStorage);
        }
    }

    if (phone) {
        if (phone.startsWith("05")) {
            phone = "";
            localStorage.removeItem("phone");
        }
        if (phone.startsWith("+97205")) {
            phone = "";
            localStorage.removeItem("phone");
        }
    }


    const { stageNumberContext, configContext, clientIdContext } = useContext(MainContext);
    const [currentStage, s_currentStage] = stageNumberContext;
    const [config] = configContext;
    const [clientId] = clientIdContext;

    const [notCompletedBooks, s_notCompletedBooks] = useState([]);
    const [isBookReady, s_isBookReady] = useState(false);
    const [loading, setLoading] = useState("cats");
    const [error, setError] = useState(false);
    const [bookData, setBookData] = useState({
        frontCoverData: {},
        bookPagesData: {},
    });

    const [setFocusToProgress, s_setFocusToProgress] = useState(1);

    const [previewImages] = useState([]);
    const [previewImagesStage] = useState("init");
    const [recentPrebooks, s_recentPrebooks] = useState([]);
    const [uniqueId, setUniqueId] = useState("");

    const bgVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
    };

    const itemVariants = {
        initial: { opacity: 0, scale: 0 },
        animate: { opacity: 1, scale: 1 },
    };

    const getItemAnimation = (delay, isBg) => ({
        initial: "initial",
        animate: "animate",
        variants: (isBg ? bgVariants : itemVariants),
        transition: { duration: 0.4, delay },
    });

    const getItemAnimationAnlim = (delay, isBg) => ({
        initial: "initial",
        animate: "animate",
        variants: (isBg ? bgVariants : itemVariants),
        transition: { duration: 0.4, delay, repeat: Infinity, repeatDelay: 2 },
    });

    const [Progress, setProgress] = useState({
        faceImageUrl: croppedFaceImageUrl ? faceImageUrl : "",
        templateId: templateId || "",
        eyeColor: "",
        hairColor: "",
        gender: "",
        age: age ? Number(age) : "",
        name: heroName,
        hairStyle: "",
        skinColor: "",
        email: email,
        phone: phone,
        clientId: clientId,
        eyeglasses: eyeglasses,
        bookId: "",
        bookCover: "",
        boundingBox: boundingBox ? JSON.parse(boundingBox) : "",
        croppedFaceImageUrl: croppedFaceImageUrl,
        faceDataId: faceDataId
    });

    const [imageBlob, setImageBlob] = useState(imageBlobStorage);

    useEffect(() => {
        if (Progress?.phone?.length > 6 && currentStage === STAGES_STEPS.INFO) {
            getPrebooks(Progress.phone).then(data => {
                s_recentPrebooks(data);
            }).catch(e => {
                console.log("error", e);
            });
        }
    }, [Progress?.phone, currentStage]);

    useEffect(() => {
        if (lang === "he") {
            document.dir = "rtl";
            document.title = langData?.general?.pageTitle;
        } else {
            document.dir = "ltr";
            document.title = langData?.general?.pageTitle;
        }
        document.documentElement.lang = lang;
    }, [lang]);

    useEffect(() => {
        const localStorageKey = "ebzizo_id";
        let uniqueId = localStorage.getItem(localStorageKey);
        if (!uniqueId) {
            uniqueId = nanoid();
            localStorage.setItem(localStorageKey, uniqueId);
        }
        setUniqueId(uniqueId);
    }, []);

    const setBookDataFunction = (key, value) => {
        setBookData(prevData => ({
            ...prevData,
            [key]: value
        }));
    };

    const initBook = async () => {
        identifyUser(phone);
        if (!Progress.bookId) {
            const phone = normalizePhoneNumber(Progress.phone);
            const bookData = await initBookApiV2(Progress.email, clientId, Progress.faceImageUrl, Progress.croppedFaceImageUrl, phone, Progress.eyeglasses, uniqueId);
            if (!bookData.success) {
                trackMixEvent("initBookApiV2", "fail", "");
                s_currentStage(5);
            } else {
                const bookId = bookData?.data?.bookId;
                updateProgress("bookId", bookId);
                LogRocket.identify(bookId, {
                    email: Progress.email,
                    phone: phone
                });
                trackMixEvent("initBookApiV2", "success", bookId);
                s_currentStage(STAGES_STEPS.TEMPLATE);
            }
        } else {
            trackMixEvent("initBookApiV2", "success", Progress.bookId);
            s_currentStage(STAGES_STEPS.TEMPLATE);
        }
    };

    const removePrebook = async (prebookId) => {
        await removePrebookApi(prebookId);
        getPrebooks(Progress.phone).then(data => {
            s_recentPrebooks(data);
        }).catch(e => {
            console.log("error", e);
        });
    };

    const chooseBookHandle = async (bookType, selectedBook) => {
        try {
            setLoading(`makingCover_${ bookType }`);
            let classToFind = `.book-prev-component.selected-book-page.capture-po`;
            if (selectedBook) {
                classToFind += `.bookId-${ selectedBook._id }`;
            }

            const divToCapture = document.querySelector(classToFind);
            if (!divToCapture) {
                console.error("Div not found");
                return;
            }

            let bookId = selectedBook ? selectedBook._id : Progress.bookId;
            const bookCover = selectedBook ? selectedBook.bookCover : Progress.bookCover;

            domtoimage.toBlob(divToCapture)
                .then(async (dataUrl) => {
                    setLoading("postingData");
                    const file = new File([dataUrl], "screenshot.png", { type: "image/png" });

                    const formData = new FormData();
                    formData.append("bookId", bookId);
                    formData.append("selectedCoverUrl", bookCover);
                    formData.append("file", file);
                    formData.append("croppedFaceImageUrl", Progress.croppedFaceImageUrl);

                    const savedBook = await setSelectCover(formData);

                    const message = {
                        type: "BOOK_FRAME",
                        bookId: bookId,
                        coverImageUrl: savedBook.coverImageBookThumbUrl,
                        bookType: bookType.toUpperCase(),
                        email: Progress.email
                    };

                    window.parent.postMessage(message, "*");
                })
                .catch((error) => {
                    console.error("Error capturing screenshot:", error);
                });
        } catch (e) {
            console.log("error", e);
        }
    };

    const sendData = async () => {
        setLoading("postingData");
        trackMixEvent("selectedTemplate", Progress.templateId, Progress.bookId);
        trackMixEvent("sendData", "start", Progress.bookId);
        try {
            if (bookDataStorageJson) {
                s_isBookReady(true);
                setLoading(false);
                setBookDataFunction("frontCoverData", bookDataStorageJson.frontCoverData);
                setBookDataFunction("bookPagesData", bookDataStorageJson.bookPagesData);
                return;
            }

            delete Progress.croppedFaceImageUrl;
            delete Progress.faceImageUrl;
            const response = await createBook(Progress);
            setTimeout(function() {
                const url = `${ EDITOR_URL }preview?bookId=${ response.bookId }&language=${ lang }`;
                window.location.href = url;
            }, 1500);
        } catch (error) {
            console.error("error creating book", error);
        }
    };

    const updateProgress = (key, value, toString) => {
        let normalValue = value;
        if (toString) {
            normalValue = JSON.stringify(value);
        }

        setProgress(prevProgress => ({
            ...prevProgress,
            [key]: value
        }));
        if (KEYS_TO_STORE.indexOf(key) !== -1) {
            localStorage.setItem(key, normalValue);
        }
    };

    const langData = chooseLang(lang);

    const changeStage = (val) => {
        s_currentStage(val);
    };

    let showHeader = !isBookReady;
    if (showHeader) {
        if (loading === "creatingBook") {
            showHeader = false;
        }
    }
    if (showHeader) {
        if (currentStage === 5) {
            showHeader = false;
        }
    }

    return (
        <div className={ `container lang-${ lang }` }>
            <motion.div { ...getItemAnimation(0.2, true) } className="stage">
                { showHeader && (
                    <Header
                        setFocusToProgress={ setFocusToProgress }
                        loading={ loading }
                        isBookReady={ isBookReady }
                        showLogo={ showLogo }
                        langData={ langData }
                    />
                ) }

                { !clientId && (
                    <div>No client ID</div>
                ) }

                { config && config.isActive === false && (
                    <div>The website is temporary down</div>
                ) }

                { clientId && currentStage === STAGES_STEPS.MEDIA && (
                    <Stage1
                        langData={ langData }
                        lang={ lang }
                        loading={ loading }
                        setLoading={ setLoading }
                        currentLang={ lang }
                        s_currentStage={ changeStage }
                        updateProgress={ updateProgress }
                        getItemAnimation={ getItemAnimation }
                        getItemAnimationAnlim={ getItemAnimationAnlim }
                        selectedImage={ Progress.faceImageUrl }
                        imageBlob={ imageBlob }
                        setImageBlob={ setImageBlob }
                    />
                ) }

                { clientId && currentStage === STAGES_STEPS.INFO && (
                    <Stage2
                        lang={ langData }
                        loading={ loading }
                        currentLang={ lang }
                        s_currentStage={ changeStage }
                        setLoading={ setLoading }
                        getItemAnimation={ getItemAnimation }
                        updateProgress={ updateProgress }
                        progress={ Progress }
                        initBook={ initBook }
                        recentPrebooks={ recentPrebooks }
                        removePrebook={ removePrebook }
                        imageBlob={ imageBlob }
                    />
                ) }

                { clientId && currentStage === STAGES_STEPS.OTP && (
                    <OtpStage
                        lang={ langData }
                        loading={ loading }
                        currentLang={ lang }
                        s_currentStage={ changeStage }
                        setLoading={ setLoading }
                        getItemAnimation={ getItemAnimation }
                        updateProgress={ updateProgress }
                        progress={ Progress }
                        initBook={ initBook }
                        recentPrebooks={ recentPrebooks }
                        removePrebook={ removePrebook }
                        imageBlob={ imageBlob }
                    />
                ) }

                { clientId && currentStage === 55 && (
                    <Stage3
                        lang={ langData }
                        loading={ loading }
                        currentLang={ lang }
                        getItemAnimation={ getItemAnimation }
                        setLoading={ setLoading }
                        updateProgress={ updateProgress }
                        progress={ Progress }
                        s_currentStage={ changeStage }
                        setFocusToProgress={ () => {
                            s_setFocusToProgress(setFocusToProgress + 1);
                        } }
                    />
                ) }

                { clientId && currentStage === STAGES_STEPS.TEMPLATE && (
                    <Stage4
                        setFocusToProgress={ () => {
                            s_setFocusToProgress(setFocusToProgress + 1);
                        } }
                        langData={ langData }
                        lang={ lang }
                        loading={ loading }
                        currentLang={ lang }
                        getItemAnimation={ getItemAnimation }
                        setLoading={ setLoading }
                        updateProgress={ updateProgress }
                        selectedItem={ Progress.templateId }
                        sendData={ sendData }
                        isBookReady={ isBookReady }
                        progress={ Progress }
                        bookData={ bookData }
                        isError={ error }
                        previewImages={ previewImages }
                        previewImagesStage={ previewImagesStage }
                        chooseBookHandle={ chooseBookHandle }
                        sliderV2={ sliderV2 }
                        clientId={ clientId }
                    />
                ) }
                { clientId && currentStage === 5 && (
                    <NotComplitedBooks
                        langData={ langData }
                        lang={ lang }
                        loading={ loading }
                        currentLang={ lang }
                        setLoading={ setLoading }
                        books={ notCompletedBooks }
                        chooseBookHandle={ chooseBookHandle }
                        progress={ Progress }
                        updateProgress={ updateProgress }
                        recentPrebooks={ recentPrebooks }
                    />
                ) }

            </motion.div>
        </div>
    );
}

export default Stages;
